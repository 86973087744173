import { Canvas } from 'datocms-react-ui';
import get from 'lodash.get';
import { RenderFieldExtensionCtx } from 'datocms-plugin-sdk';
import { useEffect, useState } from 'react';
// import { buildClient } from '@datocms/cma-client-browser';
import { ShopifyStoreItem } from 'har-shared/types/codes';
import { ShopifyLineItemsSchema } from './types';
import ShopifyProductInput from './children/ShopifyProductInput';

const CUSTOMER_PORTAL_URL = process.env.REACT_APP_CUSTOMER_PORTAL_URL;

type PropTypes = {
  ctx: RenderFieldExtensionCtx;
};

export function ShopifyLineItemsInput({ ctx }: PropTypes) {
  /**
   * Setup Content-Management client to pull added content
   */
  // const cmsClient = useMemo(() => {
  //   return buildClient({
  //     apiToken: ctx.currentUserAccessToken ?? null,
  //     environment: ctx.environment,
  //   });
  // }, [ctx.currentUserAccessToken]);

  /**
   * Parse current field json value, and assign default if not set
   */
  const currentValue = get(ctx.formValues, ctx.fieldPath) as string | undefined;
  let currentLineItems: ShopifyLineItemsSchema = {};
  if (currentValue && currentValue !== '') {
    try {
      currentLineItems = JSON.parse(currentValue);
    } catch (e) {}
  }

  // STATES
  const [lineItems, setLineItems] =
    useState<ShopifyLineItemsSchema>(currentLineItems);
  const [stores, setStores] = useState<Array<ShopifyStoreItem>>([]);

  useEffect(() => {
    const newJsonString =
      lineItems && Object.keys(lineItems).length
        ? JSON.stringify(lineItems)
        : null;
    try {
      const currentJsonString = currentValue
        ? JSON.stringify(JSON.parse(currentValue))
        : null;
      if (currentJsonString === newJsonString) return;
    } catch (e) {}
    ctx.setFieldValue(ctx.fieldPath, newJsonString);
  }, [lineItems]);

  useEffect(() => {
    window
      .fetch(`${CUSTOMER_PORTAL_URL ?? ''}/api/stores`)
      .then((res) => {
        if (!res.ok) {
          return Promise.reject(res);
        }
        return res.json();
      })
      .then((data) => {
        setStores(data);
      })
      .catch((e) => {
        //
      });
  }, []);

  const handleProductInput = (code: string) => (value: string | null) => {
    const newItems = { ...lineItems };
    newItems[code] = value;
    setLineItems(newItems);
  };

  return (
    <Canvas ctx={ctx}>
      <div className="mb-4 divide-y divide-white rounded bg-light">
        {stores.map((store, index) => (
          <div key={index} className="flex items-center space-x-1 px-4 py-3">
            <div className="w-32 flex-none text-sm">{store.name}</div>

            <div className="flex-auto">
              <ShopifyProductInput
                index={index}
                store={store}
                value={lineItems[store.code] ?? null}
                onUpdate={handleProductInput(store.code)}
              />
            </div>
          </div>
        ))}
      </div>
      {/*<TextareaField
        id="flow-editor-text-area"
        label="JSON generated from above field"
        name=""
        textareaInputProps={{
          rows: 6,
        }}
        onChange={() => null}
        value={currentValue ?? ''}
      />*/}
    </Canvas>
  );
}
