import { Handle, NodeProps, Position } from 'reactflow';
import NodeCard from '../NodeCard';
import { LocaleStringItem } from 'har-shared/types/cms';
import sanitize from 'sanitize-html';
import { HTMLRichTextSanitizeOptions } from '../../../../utils/html';
import { useQuestionsFlowStore } from '../../store';

type NodeData = {
  outcome_message?: LocaleStringItem[] | string;
};

const HardStopNode = (props: NodeProps<NodeData>) => {
  const {
    data: { outcome_message },
  } = props;
  const store = useQuestionsFlowStore();

  const message = outcome_message
    ? Array.isArray(outcome_message)
      ? outcome_message
          .find((i) => i.locale === store.displayLocale)
          ?.value.toString()
      : outcome_message
    : '';

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="input"
        className="!border-transparent !bg-accent ring-2 ring-gray-100
        before:absolute before:-ml-px before:-mt-1 before:block before:h-0 before:w-0 before:border-y-[6px] before:border-l-[8px] before:border-y-transparent before:border-l-gray-500 before:content-[''] "
      />
      <NodeCard color="primary" variant="card" className="rounded-full">
        <p className="my-0">Hard Stop</p>
        {!!message && (
          <div className="rounded bg-black/10 p-1 text-2xs">
            <p className="opacity-50 ">Message</p>
            <div className="leading-0 line-clamp-6 whitespace-pre-line italic">
              <div
                className="prose prose-2xs"
                dangerouslySetInnerHTML={{
                  __html: message
                    ? sanitize(message, HTMLRichTextSanitizeOptions)
                    : '',
                }}></div>
            </div>
          </div>
        )}
      </NodeCard>
    </>
  );
};

export default HardStopNode;
