import { useEffect, useState } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import NodeCard from '../NodeCard';
import { LogicProductsRow } from '../../../../types/flow';
import { Client } from '@datocms/cma-client-browser';
import ResultIcon from '../../../../icons/ResultIcon';
import sanitize from 'sanitize-html';
import { HTMLRichTextSanitizeOptions } from '../../../../utils/html';
import { useQuestionsFlowStore } from '../../store';

type NodeData = {
  label: string;
  products?: LogicProductsRow[] | null;
  cmsClient: Client;
  onEditItem?: (itemId: string) => {};
};

const EndNode = (props: NodeProps<NodeData>) => {
  const {
    data: { products, cmsClient, onEditItem },
  } = props;

  const store = useQuestionsFlowStore();

  const isResultResourceType = products?.[0].skus?.startsWith('result://');
  const firstSku = products?.[0].skus?.split(',')[0];
  const resultId = firstSku?.replace('result://', '');

  const [resultResource, setResultResource] = useState<any>(null);
  let resultPIds: any = null;
  let productDisplay: string | null = null;
  try {
    resultPIds = JSON.parse(resultResource.products);
    productDisplay = resultPIds[Object.keys(resultPIds)[0]];
  } catch (e) {}

  useEffect(() => {
    if (resultId && isResultResourceType) {
      cmsClient.items
        .find(resultId)
        .then((item) => {
          setResultResource(item);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, []);

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="input"
        className="!border-transparent !bg-accent ring-2 ring-gray-100
        before:absolute before:-ml-px before:-mt-1 before:block before:h-0 before:w-0 before:border-y-[6px] before:border-l-[8px] before:border-y-transparent before:border-l-gray-500 before:content-[''] "
      />
      <NodeCard
        color="dark"
        variant="card"
        className={
          isResultResourceType ? '!max-w-2xl hover:z-[1000]' : 'max-w-[16rem]'
        }>
        <p className="my-0">End</p>
        {products && (
          <>
            {isResultResourceType ? (
              <div className={`text-2xs ${resultResource ? '' : 'min-h-48'}`}>
                <p className="mb-0.5 opacity-50">Result:</p>

                {resultResource ? (
                  <div
                    className="group relative rounded-lg border-2 border-transparent bg-paper px-3 py-2 text-body shadow-2 hover:border-dark active:shadow-3"
                    onDoubleClick={() =>
                      onEditItem && resultId ? onEditItem(resultId) : null
                    }>
                    <span className="absolute right-1 top-1 inline-flex whitespace-nowrap rounded bg-dark px-1 py-1 text-white">
                      <ResultIcon size={14} />
                    </span>
                    <h3 className="text-2xs font-medium text-accent">
                      {resultResource.title}
                    </h3>
                    <p className="">
                      <strong>Template:</strong>{' '}
                      <span className="uppercase">
                        {resultResource.result_template}
                      </span>
                      {productDisplay && (
                        <>
                          <span className="mx-4 inline-block text-light">
                            |
                          </span>
                          <strong>Products:</strong>{' '}
                          <span className="select-all">{productDisplay}</span>
                        </>
                      )}
                    </p>
                    <div className="absolute left-0 right-0 top-full z-[1000] hidden max-h-48 -translate-y-1 overflow-auto rounded-b-lg bg-white p-4 px-3 py-2 group-hover:block">
                      <div className="grid grid-cols-3 gap-4 break-all text-[0.5rem]">
                        <div>
                          <p>
                            <strong>Summary:</strong>
                          </p>
                          <div
                            className="prose prose-2xs"
                            dangerouslySetInnerHTML={{
                              __html: resultResource.summary?.[
                                store.displayLocale
                              ]
                                ? sanitize(
                                    resultResource.summary?.[
                                      store.displayLocale
                                    ],
                                    HTMLRichTextSanitizeOptions,
                                  )
                                : '',
                            }}></div>
                        </div>
                        <div>
                          <p>
                            <strong>Recommendation summary:</strong>
                          </p>
                          <div
                            className="prose prose-2xs"
                            dangerouslySetInnerHTML={{
                              __html: resultResource.recommendation_summary?.[
                                store.displayLocale
                              ]
                                ? sanitize(
                                    resultResource.recommendation_summary?.[
                                      store.displayLocale
                                    ],
                                    HTMLRichTextSanitizeOptions,
                                  )
                                : '',
                            }}></div>
                        </div>
                        <div>
                          <p>
                            <strong>Potential Benefits:</strong>
                          </p>
                          <div
                            className="prose prose-2xs"
                            dangerouslySetInnerHTML={{
                              __html: resultResource.potential_benefits?.[
                                store.displayLocale
                              ]
                                ? sanitize(
                                    resultResource.potential_benefits?.[
                                      store.displayLocale
                                    ],
                                    HTMLRichTextSanitizeOptions,
                                  )
                                : '',
                            }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="leading-0 mb-px rounded bg-white/10 px-1 py-px">
                    {resultId}
                  </p>
                )}
              </div>
            ) : (
              <div className="text-2xs">
                <p className="mb-0.5 opacity-50">Products:</p>
                {products.map((product, pk) => (
                  <div className="flex flex-wrap " key={pk}>
                    <span className="leading-0 mb-px me-1 rounded py-px font-bold">
                      {product.code}:
                    </span>
                    {product.skus.split(',').map((sku, k) => (
                      <p
                        className="leading-0 mb-px me-1 rounded bg-white/10 px-1 py-px"
                        key={k}>
                        {sku}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </NodeCard>
    </>
  );
};

export default EndNode;
