import { IconProps } from './base/types';
import Icon from './base/Icon';

const ChevronUpIcon = (props: IconProps) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
        fill="none"
        strokeWidth={1.5}
        d="m4.5 15.75 7.5-7.5 7.5 7.5"
      />
    </Icon>
  );
};

export default ChevronUpIcon;
