import { Button, Canvas, SwitchField, TextareaField } from 'datocms-react-ui';
import get from 'lodash.get';
import { RenderFieldExtensionCtx } from 'datocms-plugin-sdk';
import { useEffect, useMemo, useReducer, useState } from 'react';
import PlusIcon from '../../icons/PlusIcon';
import s from '../styles.module.css';
import FlowRuleItem from './children/FlowRuleItem';
import { LogicContext } from './context';
import { buildClient } from '@datocms/cma-client-browser';
import { QuestionInterface } from '../../types/Question.interface';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import FlowRuleAction from './children/FlowRuleAction';
import { flowLogicReducer, FlowLogicReducerStore } from './reducer';
import { Logic, Rule } from '../../types/flow';
import { ContentTypeEnum, RuleActionsOptions } from '../../constants';
import { getOptionLabel } from '../../utils/object';
import { ShopifyStoreItem } from 'har-shared/types/codes';
import { ResultResourceItem } from './types';

type PropTypes = {
  ctx: RenderFieldExtensionCtx;
};

const CUSTOMER_PORTAL_URL = process.env.REACT_APP_CUSTOMER_PORTAL_URL;

export function FlowRulesEditor({ ctx }: PropTypes) {
  /**
   * Setup Content-Management client to pull added content
   */
  const cmsClient = useMemo(() => {
    return buildClient({
      apiToken: ctx.currentUserAccessToken ?? null,
      environment: ctx.environment,
    });
  }, [ctx.currentUserAccessToken]);

  /**
   * Parse current field json value, and assign default if not set
   */
  const currentValue = get(ctx.formValues, ctx.fieldPath) as string | undefined;
  let currentRuleGroups: Logic = {
    rules: [],
    fallback: {
      action: 'goto',
    },
  };
  if (currentValue && currentValue !== '') {
    try {
      currentRuleGroups = JSON.parse(currentValue);
    } catch (e) {}
  }

  // STATES
  const [logic, logicDispatch] = useReducer(
    flowLogicReducer,
    currentRuleGroups,
  );
  const [expandedItems, setExpandedItems] = useState<number[]>([]);
  const [allQuestionsList, setAllQuestionsList] = useState<
    Array<QuestionInterface>
  >([]);
  const [questionsList, setQuestionsList] = useState<Array<QuestionInterface>>(
    [],
  );
  const [showJson, setShowJson] = useState<boolean>(false);
  const [stores, setStores] = useState<Array<ShopifyStoreItem>>([]);

  const logicStore = new FlowLogicReducerStore(logic, logicDispatch);

  const [resultsStack, setResultsStack] = useState<
    Record<string, ResultResourceItem>
  >({});

  const expandItem = (index: number) => {
    if (expandedItems.includes(index)) return;
    const items = [...expandedItems];
    items.push(index);
    setExpandedItems(items);
  };
  const expandAll = () => {
    const items = logic.rules.map((_, i) => i);
    items.push(-1);
    setExpandedItems(items);
  };
  const collapseItem = (index: number) => {
    const i = expandedItems.findIndex((item) => item === index);
    if (i === -1) return;
    const items = [...expandedItems];
    items.splice(i, 1);
    setExpandedItems(items);
  };
  const collapseAll = () => {
    setExpandedItems([]);
  };

  const duplicateItem = (index: number) => {
    logicStore.duplicateRule(index);
  };
  const moveItemUp = (index: number) => {
    if (index <= 0) return;
    logicStore.reorderRule(index, index - 1);
  };
  const moveItemTop = (index: number) => {
    if (index <= 0) return;
    logicStore.reorderRule(index, 0);
  };
  const moveItemDown = (index: number) => {
    if (index >= logic.rules.length - 1) return;
    logicStore.reorderRule(index, index + 1);
  };
  const moveItemBottom = (index: number) => {
    if (index >= logic.rules.length - 1) return;
    logicStore.reorderRule(index, logic.rules.length - 1);
  };

  // Update CMS field everytime logic state is updated
  useEffect(() => {
    const newJsonString = logic ? JSON.stringify(logic) : null;
    try {
      const currentJsonString = currentValue
        ? JSON.stringify(JSON.parse(currentValue))
        : null;
      if (currentJsonString === newJsonString) return;
    } catch (e) {}
    ctx.setFieldValue(ctx.fieldPath, newJsonString);
  }, [logic]);

  useEffect(() => {
    // Fetch all available questions to populate option fields
    cmsClient.items
      .list({
        filter: {
          type: 'question',
        },
        page: {
          limit: 450,
        },
        order_by: '_created_at_ASC',
      })
      .then((data) => {
        setAllQuestionsList(data as any);

        // Questions list without content types
        setQuestionsList(
          (data as unknown as QuestionInterface[]).filter((q) => {
            return ![
              ContentTypeEnum.social_content,
              ContentTypeEnum.user_form,
            ].find((t) => t === q.question_type);
          }),
        );
      });

    fetch(`${CUSTOMER_PORTAL_URL ?? ''}/api/stores`)
      .then((res) => {
        if (!res.ok) {
          return Promise.reject(res);
        }
        return res.json();
      })
      .then((data) => {
        setStores(data);
      })
      .catch((e) => {
        //
      });
  }, []);

  // Handle Inputs
  const handleAddRule = () => {
    logicStore.addRule();
    expandItem(logic.rules?.length - 1);
  };
  const handleOtherwiseUpdate = (fallback: Partial<Rule>) => {
    logicStore.updateFallback({
      action: fallback.action || 'goto',
      goto_question_id: fallback.goto_question_id,
      outcome_message: fallback.outcome_message,
      products: fallback.products,
    });
  };

  const addResultToStack = (item: ResultResourceItem) => {
    if (!item.id) {
      //|| resultsStack[item.id]) {
      return;
    }
    const stack = { ...resultsStack };
    stack[item.id] = item;
    setResultsStack(stack);
  };

  useEffect(() => {
    const resultsResourceIds: string[] = [];
    logic.rules.forEach((i) => {
      if (i.action === 'end' && i.products?.[0]?.skus.startsWith('result://')) {
        resultsResourceIds.push(i.products[0].skus.replace('result://', ''));
      }
    });
    if (
      logic.fallback?.action === 'end' &&
      logic.fallback.products?.[0]?.skus.startsWith('result://')
    ) {
      resultsResourceIds.push(
        logic.fallback.products[0].skus.replace('result://', ''),
      );
    }
    if (resultsResourceIds.length) {
      cmsClient.items
        .list({
          filter: {
            ids: resultsResourceIds.join(','),
          },
        })
        .then((items) => {
          const nItems: Record<string, ResultResourceItem> = {};
          items.forEach((item) => {
            nItems[item.id] = item as ResultResourceItem;
          });
          setResultsStack(nItems);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, []);

  return (
    <Canvas ctx={ctx}>
      <LogicContext.Provider
        value={{
          ctx,
          logic,
          logicStore,
          expandedItems,
          expandItem,
          collapseItem,
          duplicateItem,
          moveItemUp,
          moveItemDown,
          moveItemTop,
          moveItemBottom,
          cmsClient,
          allQuestionsList,
          questionsList,
          stores,
          resultsStack,
          addResultToStack,
        }}>
        <section className={s.riSection}>
          <div className="mb-1 flex justify-end gap-1">
            <Button
              buttonSize="xxs"
              buttonType="muted"
              disabled={expandedItems.length === 0}
              onClick={collapseAll}>
              Collapse all
            </Button>
            <Button
              buttonSize="xxs"
              buttonType="muted"
              disabled={expandedItems.length === logic.rules.length + 1}
              onClick={expandAll}>
              Expand all
            </Button>
          </div>
          {logic.rules.map((rule, index) => {
            return <FlowRuleItem key={index} rule={rule} ruleIndex={index} />;
          })}

          <div
            className={`${s.riCard} ${
              expandedItems.includes(-1) ? s.riCardExpanded : ''
            }`}>
            <div
              className={s.riRuleHead}
              onClick={() =>
                expandedItems.includes(-1) ? collapseItem(-1) : expandItem(-1)
              }>
              <span
                className={`${s.riRuleHeadIcon} ${
                  expandedItems.includes(-1) ? s.riRuleHeadIconOpen : ''
                }`}>
                <ChevronRightIcon />
              </span>
              <span className={s.riRuleLabel}>
                {logic.rules && logic.rules.length
                  ? 'All other cases'
                  : 'Always'}
              </span>
              {logic.fallback.action && (
                <span className={s.riRuleSummary}>
                  {getOptionLabel(RuleActionsOptions, logic.fallback.action)}{' '}
                  {logic.fallback.action === 'goto' &&
                    logic.fallback.goto_question_id && (
                      <b className={s.riBadge}>
                        {
                          allQuestionsList.find(
                            (q) => q.id === logic.fallback.goto_question_id,
                          )?.title?.en
                        }
                      </b>
                    )}
                </span>
              )}
            </div>
            {expandedItems.includes(-1) && (
              <div className={s.riRuleDetails}>
                <FlowRuleAction
                  rule={logic.fallback}
                  ruleIndex={-1}
                  onUpdate={handleOtherwiseUpdate}
                />
              </div>
            )}
          </div>

          <Button
            buttonSize="xxs"
            leftIcon={<PlusIcon />}
            onClick={handleAddRule}>
            Add Rule
          </Button>
        </section>

        <div className={s.riJsonToolbar}>
          <SwitchField
            id="show-json"
            name=""
            label="Show JSON"
            value={showJson}
            onChange={() => setShowJson(!showJson)}
          />
        </div>

        {showJson && (
          <TextareaField
            id="flow-editor-text-area"
            label="JSON generated from above field"
            name=""
            textareaInputProps={{
              rows: 6,
            }}
            onChange={() => null}
            value={currentValue}
          />
        )}
      </LogicContext.Provider>
    </Canvas>
  );
}
