import { IconProps } from './base/types';
import Icon from './base/Icon';

const ChevronDownIcon = (props: IconProps) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
        strokeWidth={1.5}
        fill="none"
        d="m19.5 8.25-7.5 7.5-7.5-7.5"
      />
    </Icon>
  );
};

export default ChevronDownIcon;
