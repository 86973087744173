import { Handle, NodeProps, Position } from 'reactflow';
import { QuestionInterface } from '../../../../types/Question.interface';
import { printLocaleString } from '../../../../utils/string';
import NodeCard from '../NodeCard';
import QuestionBadge from '../../../../components/QuestionBadge';
import { useQuestionsFlowStore } from '../../store';

type NodeData = QuestionInterface & {
  // label: string;
};

const QuestionNode = (props: NodeProps<NodeData>) => {
  const { data: question } = props;
  const { highlightedNode, nodeErrors } = useQuestionsFlowStore();
  const store = useQuestionsFlowStore();

  const questionErrors = nodeErrors[question.id] ?? [];
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="input"
        className="!border-transparent !bg-accent ring-2 ring-gray-100
        before:absolute before:-ml-px before:-mt-1 before:block before:h-0 before:w-0 before:border-y-[6px] before:border-l-[8px] before:border-y-transparent before:border-l-gray-500 before:content-[''] "
      />
      <NodeCard
        color="paper"
        title="Question"
        highlighted={highlightedNode === question.id}
        className="group">
        <span className="absolute right-1 top-1">
          <QuestionBadge question={question} />
        </span>
        <span className="block">
          {question?.backend_label
            ? `[${question.backend_label}] ${printLocaleString(
                question?.title,
                store.displayLocale,
              )}`
            : printLocaleString(question?.title, store.displayLocale)}
        </span>
        {questionErrors.length > 0 && (
          <span className="absolute bottom-full left-0 -translate-x-2 translate-y-3 rounded-lg bg-red-500 px-1.5 pb-px pt-0.5 font-mono text-2xs font-bold text-white">
            <span className="hidden whitespace-nowrap group-hover:block">
              {questionErrors.map((e, k) => (
                <p className="mb-1 mt-0" key={k}>
                  {e}
                </p>
              ))}
            </span>
            <span className="block">
              {questionErrors.length === 1
                ? `${questionErrors.length} error`
                : `${questionErrors.length} errors`}
            </span>
          </span>
        )}
      </NodeCard>
      <Handle
        type="source"
        position={Position.Right}
        id="output"
        className="!border-transparent !bg-accent ring-2 ring-gray-100"
      />
    </>
  );
};

export default QuestionNode;
