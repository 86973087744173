import React, { MouseEventHandler, useEffect, useRef } from 'react';
import { CaretDownIcon, CaretUpIcon, Spinner } from 'datocms-react-ui';
import { ShopifyStoreItem } from 'har-shared/types/codes';
import { useState } from 'react';
import IconShopify from './IconShopify';

type PropTypes = {
  index: number;
  store: ShopifyStoreItem;
  value?: string | null;
  onUpdate: (value: string | null) => void;
};

const CUSTOMER_PORTAL_URL = process.env.REACT_APP_CUSTOMER_PORTAL_URL;

export default function ShopifyProductInput(props: PropTypes) {
  const { value, onUpdate, store } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [term, setTerm] = useState<string>('');
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [isFetchingSelected, setIsFetchingSelected] = useState(false);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const fetchController = useRef(new AbortController());

  const fetchSuggestions = () => {
    setIsFetching(true);
    if (fetchController.current) {
      fetchController.current.abort();
      fetchController.current = new AbortController();
    }
    fetch(
      `${CUSTOMER_PORTAL_URL}/api/public/shopify/storefront/query?country=${store.code}`,
      {
        method: 'POST',
        signal: fetchController.current.signal,
        body: `{
              products(query: "${term}", first: 30) {
                nodes {
                    id
                    title
                    handle
                    featuredImage {
                      altText
                      url
                    }
                }
              }
            }`,
      },
    )
      .then((res) => {
        if (!res.ok) return Promise.reject(res);
        return res.json();
      })
      .then((data) => {
        setSuggestions(data?.products?.nodes ?? []);
      })
      .catch(() => {
        setSuggestions([]);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const fetchSelectedProduct = () => {
    if (!value || value === '') {
      setSelectedProduct(null);
      return;
    }
    setIsFetchingSelected(true);
    fetch(
      `${CUSTOMER_PORTAL_URL}/api/public/shopify/storefront/query?country=${
        store.code
      }&value=${encodeURIComponent(value)}`,
      {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Cache-Control': 'no-cache, no-transform',
        },
        body: `{
              product(id: "${value}") {
                id
                title
                handle
                featuredImage {
                  altText
                  url
                }
              }
            }`,
      },
    )
      .then((res) => {
        if (!res.ok) return Promise.reject(res);
        return res.json();
      })
      .then((data) => {
        setSelectedProduct(data?.product ?? null);
      })
      .catch(() => {
        setSelectedProduct(null);
      })
      .finally(() => {
        setIsFetchingSelected(false);
      });
  };

  function handleToggle() {
    const newState = !isOpen;
    setIsOpen(newState);
    if (newState) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 50);
    }
  }

  const handleSelect =
    (id: string | null): MouseEventHandler<HTMLDivElement> =>
    (_) => {
      onUpdate(id);
      setIsOpen(false);
    };

  function handleClickOutSide(e: MouseEvent) {
    if (e.target && !wrapperRef.current?.contains(e.target as any)) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    fetchSuggestions();
  }, [term]);
  useEffect(() => {
    if (isOpen) fetchSuggestions();
  }, [isOpen]);

  useEffect(() => {
    if (value && !selectedProduct) {
      setIsFetchingSelected(true);
      setTimeout(
        () => {
          fetchSelectedProduct();
        },
        (props.index + 1) * 500,
      );
    } else {
      fetchSelectedProduct();
    }
  }, [value]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide);
    return () => {
      document.removeEventListener('click', handleClickOutSide);
    };
  });

  return (
    <div className="relative" ref={wrapperRef}>
      <button
        type="button"
        onClick={handleToggle}
        className={`inline-flex min-h-14 w-full items-center justify-start border bg-white p-2.5 text-start hover:ring-2 hover:ring-accent-overlay ${
          isOpen ? 'border-accent' : 'border-default'
        }`}>
        {isFetchingSelected ? (
          <span className="flex items-center justify-center text-gray-800">
            <Spinner size={24} />
          </span>
        ) : value ? (
          <React.Fragment>
            {selectedProduct ? (
              <span className="flex items-center">
                <span className="me-4 w-4 flex-none">
                  <IconShopify />
                </span>
                <span className="flex-auto leading-tight">
                  <span className="block text-sm">{selectedProduct.title}</span>
                  <span className="mt-px block text-xs text-light">
                    {selectedProduct.handle}
                  </span>
                </span>
              </span>
            ) : (
              <span className="text-gray-800">{value}</span>
            )}
          </React.Fragment>
        ) : (
          <span className="text-placeholder">Select Product</span>
        )}
        {isOpen ? (
          <CaretUpIcon className="absolute end-2 top-1/2 -translate-y-1/2" />
        ) : (
          <CaretDownIcon className="absolute end-2 top-1/2 -translate-y-1/2" />
        )}
      </button>
      {isOpen && (
        <div className="absolute left-0 right-0 top-full z-10 bg-white shadow">
          <input
            ref={searchInputRef}
            type="search"
            className="block w-full border border-default p-2.5 text-sm transition hover:border-darker focus:border-accent focus:shadow-accent-overlay focus:outline-0"
            value={term}
            placeholder="Search product"
            onChange={(e) => setTerm(e.target.value)}
          />
          {isFetching ? (
            <div className="flex items-center justify-center py-8">
              <Spinner size={24} />
            </div>
          ) : (
            <div className="max-h-52 divide-y divide-default overflow-auto">
              {!suggestions || !suggestions.length ? (
                <div className="flex items-center justify-center px-4 py-4 text-light">
                  No products found.
                </div>
              ) : (
                suggestions.map((product) => (
                  <div
                    key={product.id}
                    className="cursor-pointer px-4 py-2 hover:bg-light"
                    onClick={handleSelect(product.id)}>
                    <span className="flex items-center">
                      <span className="me-4 w-4 flex-none">
                        <IconShopify />
                      </span>
                      <span className="flex-auto leading-tight">
                        <span className="block text-sm">{product.title}</span>
                        <span className="mt-px block text-xs text-light">
                          {product.handle}
                        </span>
                      </span>
                    </span>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
