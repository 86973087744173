import { create } from 'zustand';
import { SurveyInterface } from '../../types/Survey.interface';
import { addEdge, applyEdgeChanges, applyNodeChanges } from 'reactflow';
import type { Connection, Node, Edge, EdgeChange, NodeChange } from 'reactflow';
import { QuestionInterface } from '../../types/Question.interface';
import { QuestionErrors } from '../../types/flow';
import { QuestionOptionInterface } from '../../types/QuestionOption.interface';

type VisualFlowStore = {
  loading: boolean;
  setLoading: (isLoading: boolean) => void;

  surveysList: SurveyInterface[];
  setSurveysList: (survey: SurveyInterface[]) => void;
  survey: SurveyInterface | null;
  setSurvey: (survey: SurveyInterface | null) => void;
  questions: QuestionInterface[];
  setQuestions: (questions: QuestionInterface[]) => void;
  questionOptions: QuestionOptionInterface[];
  setQuestionOptions: (options: QuestionOptionInterface[]) => void;

  nodes: Node[];
  edges: Edge[];
  setNodes: (data: Node[]) => void;
  setEdges: (data: Edge[]) => void;
  onNodesChange: (changes: NodeChange[]) => void;
  onEdgesChange: (changes: EdgeChange[]) => void;
  onConnect: (connection: Connection) => void;

  highlightedNode: string | null;
  setHighlightedNode: (nodeId: string | null) => void;

  nodeErrors: QuestionErrors;

  setGraphData: (data: {
    nodes: Node[];
    edges: Edge[];
    nodeErrors: QuestionErrors;
  }) => void;

  showRules: boolean;
  setShowRules: (showRules: boolean) => void;

  displayLocale: string;
  setDisplayLocale: (displayLocale: string) => void;
};

export const useQuestionsFlowStore = create<VisualFlowStore>((set, get) => ({
  loading: false,
  setLoading: (isLoading: boolean) => set(() => ({ loading: isLoading })),

  surveysList: [],
  setSurveysList: (surveysList) =>
    set((state) => {
      let selectedSurvey = state.survey;
      if (!selectedSurvey && surveysList?.[0]) {
        selectedSurvey = surveysList[0] as any;
      }
      return { surveysList: surveysList, survey: selectedSurvey };
    }),
  survey: null,
  setSurvey: (survey) => set((state) => ({ survey })),
  questions: [],
  setQuestions: (questions) => {
    return set((state) => ({ questions }));
  },
  questionOptions: [],
  setQuestionOptions: (questionOptions) => {
    return set((state) => ({ questionOptions }));
  },

  nodes: [],
  edges: [],
  setNodes: (data) => {
    set({ nodes: data });
  },
  setEdges: (data) => {
    set({ edges: data });
  },
  onNodesChange: (changes: NodeChange[]) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },
  onConnect: (connection: Connection) => {
    set({
      edges: addEdge(connection, get().edges),
    });
  },

  highlightedNode: null,
  setHighlightedNode: (nodeId: string | null) =>
    set(() => ({ highlightedNode: nodeId })),

  nodeErrors: {},
  sourceMap: {},
  targetMap: {},

  setGraphData: ({ nodes, edges, nodeErrors }) => {
    set({ nodes, edges, nodeErrors });
  },

  showRules: false,

  setShowRules: (showRules: boolean) => {
    set({ showRules });
  },

  displayLocale: 'en',
  setDisplayLocale: (displayLocale: string) => {
    set({ displayLocale });
  },
}));
