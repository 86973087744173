export const HTMLSanitizeOptions = {
  allowedTags: [
    'b',
    'i',
    'em',
    'strong',
    'a',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'ul',
    'ol',
    'li',
  ],
  allowedAttributes: {
    a: ['href'],
  },
};
export const HTMLRichTextSanitizeOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'ul', 'ol', 'li'],
  allowedAttributes: {
    a: ['href', 'title'],
    ul: ['class'],
  },
};
export const HTMLInlineRichTextSanitizeOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a'],
  allowedAttributes: {
    a: ['href', 'title'],
  },
};
