import { LogicProductsRow } from '../../../types/flow';
import { RULE_ACTION_PRODUCTS_SEPARATOR } from '../../../constants';
import { useLogicContext } from '../context';
import React, { useEffect, useState } from 'react';
import { CaretDownIcon } from 'datocms-react-ui';
import { ResultResourceItem } from '../types';

type PropTypes = {
  ruleIndex: number;
  value?: LogicProductsRow[] | null;
  onUpdate: (value: LogicProductsRow[] | null) => void;
};

type LogicProductsRowDecoded = {
  code: string;
  skus: string[];
};

const encodeItems = (
  items: LogicProductsRowDecoded[] | null,
): LogicProductsRow[] | null => {
  return (
    items?.map((v) => ({
      code: v.code,
      skus: v.skus.join(RULE_ACTION_PRODUCTS_SEPARATOR),
    })) ?? null
  );
};
const decodeItems = (
  value: LogicProductsRow[] | null,
): LogicProductsRowDecoded[] | null => {
  return (
    value?.map((v) => ({
      code: v.code,
      skus: v.skus
        .split(RULE_ACTION_PRODUCTS_SEPARATOR)
        .map((v2: string) => v2.trim()),
    })) ?? null
  );
};

const FlowRuleActionResultPicker = (props: PropTypes) => {
  const { value, onUpdate } = props;
  const { stores, ctx, cmsClient, addResultToStack, resultsStack } =
    useLogicContext();

  const ResultResourceItemType = Object.values(ctx.itemTypes).find((t) => {
    return t?.attributes.name.toLowerCase() === 'result';
  });

  const items: LogicProductsRowDecoded[] | null = (value &&
    value.length &&
    decodeItems(value)) || [
    {
      code: `${stores?.[0].code ?? ''}`,
      skus: [''],
    },
  ];

  const updateItems = (id: string | null) => {
    const newItems = stores.map((store) => {
      return { code: store.code, skus: [id ? `result://${id}` : ''] };
    });
    onUpdate(encodeItems(newItems));
  };

  const handleClick = () => {
    if (!ResultResourceItemType) return;
    ctx
      .selectItem(ResultResourceItemType.id, {
        multiple: false,
      })
      .then((item) => {
        if (!item) return;
        setSelectedResource(item);
        addResultToStack(item as ResultResourceItem);
        updateItems(item?.id ?? null);
      });
  };

  const resourceId = items?.[0].skus?.[0].startsWith('result://')
    ? items?.[0].skus?.[0].replace('result://', '')
    : null;
  const [selectedResource, setSelectedResource] = useState<any>(null);

  useEffect(() => {
    if (resourceId) {
      if (resultsStack[resourceId]) {
        setSelectedResource(resultsStack[resourceId]);
        return;
      }
      cmsClient.items
        .find(resourceId)
        .then((item) => {
          if (item) {
            setSelectedResource(item);
            addResultToStack(item as ResultResourceItem);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, []);
  return (
    <div className="space-y-2">
      <button
        type="button"
        onClick={handleClick}
        className="relative inline-flex min-h-14 w-full items-center justify-start border bg-white p-2.5 text-start hover:ring-2 hover:ring-accent-overlay">
        {resourceId ? (
          <React.Fragment>
            {selectedResource ? (
              <span className="flex items-center">
                <span className="flex-auto leading-tight">
                  <span className="block text-sm">
                    {selectedResource.attributes?.title ??
                      selectedResource.title}
                  </span>
                  <span className="mt-px block text-xs text-light">
                    {selectedResource.id}
                  </span>
                </span>
              </span>
            ) : (
              <span className="text-gray-800">{resourceId}</span>
            )}
          </React.Fragment>
        ) : (
          <span className="text-placeholder">Select Result</span>
        )}
        <CaretDownIcon className="absolute end-2 top-1/2 -translate-y-1/2" />
      </button>
    </div>
  );
};

export default FlowRuleActionResultPicker;
