import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSimpleBezierPath,
  getSmoothStepPath,
  Position,
} from 'reactflow';
import { useQuestionsFlowStore } from '../../store';
import { Condition, Rule } from '../../../../types/flow';

function RuleEdgeCondition({ condition }: { condition: Condition }) {
  const store = useQuestionsFlowStore();
  const question = store.questions.find((q) => {
    return q.id === condition.ref_question_id;
  });
  if (!question) return <span></span>;
  let optionsIds = condition.value.split(',');
  let optionsLabels: string[] = [];
  optionsIds?.forEach((id) => {
    const op = store.questionOptions.find((opt) => opt.id === id);
    if (op?.text?.en) {
      optionsLabels.push(op.text.en);
    }
  });
  return (
    <span className="flex items-start gap-1 px-px py-1 font-normal">
      <span className="whitespace-nowrap">[{question.backend_label}]</span>
      <span className="opacity-50">{condition.compare}</span>
      <span>
        {optionsLabels.length ? optionsLabels.join(', ') : condition.value}
      </span>
    </span>
  );
}

export default function RuleEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  label,
  data,
  selected,
}: EdgeProps) {
  // const { setEdges } = useReactFlow();
  const [edgePath, labelX, labelY] =
    data?.rule?.action === 'end'
      ? getSmoothStepPath({
          sourceX,
          sourceY,
          targetX,
          targetY,
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
        })
      : getSimpleBezierPath({
          sourceX,
          sourceY,
          targetX,
          targetY,
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
        });

  const store = useQuestionsFlowStore();

  const rule: Rule | null = data?.rule ?? null;

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{
          strokeWidth: 2,
        }}
      />
      <EdgeLabelRenderer>
        <span
          className="nodrag nopan absolute max-w-[12rem] rounded-lg bg-paper px-sm py-1 text-xs font-medium capitalize text-accent"
          style={{
            // transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            transform:
              rule?.action === 'end'
                ? `translate(-100%, -50%) translate(${targetX}px,${targetY}px)`
                : `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            ...(selected ? { zIndex: 1000 } : {}),
          }}>
          {rule ? (
            <>
              <span className="block leading-none">{label}</span>
              <span className="relative block">
                {(selected || store.showRules) && (
                  <span
                    className={` block
                  ${
                    store.showRules
                      ? ''
                      : ' absolute -left-sm top-full z-[1000] w-44 rounded-md bg-paper px-1 pb-1 shadow'
                  }
                  `}>
                    {rule.condition_groups?.map((group, index) => (
                      <span
                        className="mt-1 block divide-y divide-gray-100 rounded-sm border border-gray-100 p-px text-[0.5rem] leading-none"
                        key={index}>
                        {group.conditions.map((condition, k) => (
                          <RuleEdgeCondition condition={condition} key={k} />
                        ))}
                      </span>
                    ))}
                  </span>
                )}
              </span>
            </>
          ) : (
            <span>{label}</span>
          )}
        </span>
      </EdgeLabelRenderer>
    </>
  );
}
