import { ReactNode, useEffect, useRef, useState } from 'react';
import BoldIcon from '../icons/BoldIcon';
import ItalicIcon from '../icons/ItalicIcon';
import StrikeThroughIcon from '../icons/StrikeThroughIcon';
import BulletListIcon from '../icons/BulletListIcon';
import NumberedListIcon from '../icons/NumberedListIcon';
import LinkIcon from '../icons/LinkIcon';

type PropType = {
  valueIdentifier?: string | null;
  value?: string | null;
  onChange: (value: string | null) => void;
  placeholder?: string;
  noNl?: boolean;
  noHtml?: boolean;
  enabledActions?: Array<string>;
};
type ActionItem = {
  key: string;
  icon?: string | ReactNode;
  iconClass?: string;
  title: string;
  state?: () => boolean;
  result: () => void;
};

const defaultParagraphSeparator = 'p';
const defaultParagraphSeparatorString = 'defaultParagraphSeparator';
const formatBlock = 'formatBlock';

function replaceAll(str: string, search: string, replacement: string) {
  return str.split(search).join(replacement);
}

export default function RichTextInput(props: PropType) {
  props = {
    enabledActions: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'olist',
      'ulist',
      'link',
    ],
    ...props,
  };

  const { value, onChange } = props;

  const [defaultValue, setDefaultValue] = useState(value);
  const element = useRef<HTMLDivElement | null>(null);
  const [activeActions, setActiveActions] = useState<string[]>([]);

  const defaultActions: ActionItem[] = [
    {
      key: 'bold',
      icon: <BoldIcon />,
      iconClass: 'font-bold',
      title: 'Bold',
      state: () => queryCommandState('bold'),
      result: () => exec('bold'),
    },
    {
      key: 'italic',
      icon: <ItalicIcon />,
      iconClass: 'italic',
      title: 'Italic',
      state: () => queryCommandState('italic'),
      result: () => exec('italic'),
    },
    {
      key: 'underline',
      icon: 'U',
      iconClass: 'underline',
      title: 'Underline',
      state: () => queryCommandState('underline'),
      result: () => exec('underline'),
    },
    {
      key: 'strikethrough',
      icon: <StrikeThroughIcon />,
      iconClass: 'line-through',
      title: 'Strike-through',
      state: () => queryCommandState('strikeThrough'),
      result: () => exec('strikeThrough'),
    },
    {
      key: 'heading1',
      icon: 'H1',
      iconClass: 'font-bold text-[70%] first-letter:text-sm tracking-wider',
      title: 'Heading 1',
      result: () => exec(formatBlock, '<H1>' as any),
    },
    {
      key: 'heading2',
      icon: 'H2',
      iconClass: 'font-bold text-[70%] first-letter:text-sm tracking-wider',
      title: 'Heading 2',
      result: () => exec(formatBlock, '<H2>' as any),
    },
    // {
    //   key: 'paragraph',
    //   icon: 'P',
    //   title: 'Paragraph',
    //   result: () => exec(formatBlock, '<p>' as any)
    // },
    {
      key: 'quote',
      icon: '&#8220; &#8221;',
      // icon: 'i-tabler-quote',
      title: 'Quote',
      result: () => exec(formatBlock, '<blockquote>' as any),
    },
    {
      key: 'olist',
      // icon: '&#35;',
      icon: <NumberedListIcon />,
      title: 'Ordered List',
      result: () => exec('insertOrderedList'),
    },
    {
      key: 'ulist',
      // icon: '&#8226;',
      icon: <BulletListIcon />,
      title: 'Unordered List',
      result: () => exec('insertUnorderedList'),
    },
    {
      key: 'code',
      // icon: '&lt;/&gt;',
      title: 'Code',
      result: () => exec(formatBlock, '<pre>' as any),
    },
    {
      key: 'line',
      // icon: '&#8213;',
      icon: '—',
      title: 'Horizontal Line',
      result: () => exec('insertHorizontalRule'),
    },
    {
      key: 'link',
      // icon: '&#128279;',
      icon: <LinkIcon />,
      title: 'Link',
      result: () => {
        const url = window.prompt('Enter the link URL');
        if (url) exec('createLink', url as any);
      },
    },
  ];

  const actions = defaultActions.filter(
    (a) => props.enabledActions?.includes(a.key),
  );

  /**
   * FUNCTIONS
   */
  const exec = (command: string, value?: any) =>
    window.document.execCommand(command, false, value);
  const queryCommandState = (command: string) =>
    document?.queryCommandState(command);
  const queryCommandValue = (command: string) =>
    document.queryCommandValue(command);

  function currentContent() {
    return props.noHtml
      ? element.current!.innerText
      : element.current!.innerHTML;
  }

  function updateContent(newcontent: string) {
    if (props.noHtml) {
      element.current!.innerText = newcontent;
    } else {
      element.current!.innerHTML = newcontent;
    }
  }

  function update() {
    const current = currentContent();
    if (!props.noHtml && current.length === 1) {
      // element.value!.innerHTML = '<p>' + current + '</p>'
      exec(formatBlock, `<${defaultParagraphSeparator}>`);
    }
    onChange(currentContent());
  }

  function onPaste(event: any) {
    event.preventDefault();
    let text = (event.originalEvent || event).clipboardData.getData(
      'text/plain',
    );
    if (props.noNl) {
      text = replaceAll(text, '\r\n', ' ');
      text = replaceAll(text, '\n', ' ');
      text = replaceAll(text, '\r', ' ');
    }
    exec('insertText', text);
  }
  function onKeypress(event: any) {
    if (event.key === 'Enter' && props.noNl) {
      event.preventDefault();
      // emit('returned', currentContent());
      return;
    }
    if (
      event.key === 'Enter' &&
      queryCommandValue(formatBlock) === 'blockquote'
    ) {
      setTimeout(() => exec(formatBlock, `<${defaultParagraphSeparator}>`), 0);
      return;
    }
    if (
      event.key === 'Enter' &&
      queryCommandValue(formatBlock) === '' &&
      !element.current?.innerHTML.startsWith('<')
    ) {
      // setTimeout(() => exec(formatBlock, `<${defaultParagraphSeparator}>`), 0)
    }
  }
  function onFocus() {
    updateActiveActions();
  }
  function onEditableClick() {
    updateActiveActions();
  }

  const updateActiveActions = () => {
    setActiveActions(
      actions
        .filter((action) => {
          return action.state && action.state();
        })
        .map((a) => a.key),
    );
  };

  useEffect(() => {
    updateContent(value ?? '');

    exec('styleWithCSS', false);
    exec(defaultParagraphSeparatorString, 'p');
  }, []);

  /*useEffect(() => {
    updateContent(value ?? '');
  }, [value]);*/

  useEffect(() => {
    let pValue = value;
    if (value && value !== '' && !value.includes('<p>')) {
      pValue = '<p>' + value + '</p>';
    }
    setDefaultValue(pValue);
    updateContent(pValue ?? '');
  }, [props.valueIdentifier]);

  useEffect(() => {
    let pValue = defaultValue;
    if (defaultValue && defaultValue !== '' && !defaultValue.includes('<p>')) {
      pValue = '<p>' + defaultValue + '</p>';
    }
    updateContent(pValue ?? '');
  }, [defaultValue]);

  return (
    <div className="w-full rounded-md bg-white">
      <div className="items-bottom flex flex-none flex-wrap overflow-hidden rounded-t-md border-x border-t border-gray-300 bg-white">
        {actions.map((action) => (
          <div
            key={action.key}
            className="-mb-px flex-none border-b border-e border-gray-100 p-px">
            <button
              type="button"
              tabIndex={-1}
              title={action.title}
              className={`flex h-8 min-w-8 items-center justify-center text-sm hover:bg-gray-50 ${
                activeActions.includes(action.key) ? 'bg-gray-100' : ''
              }`}
              onClick={action.result}>
              {typeof action.icon === 'string' ? (
                <span className={action.iconClass}>{action.icon}</span>
              ) : action.icon ? (
                <span className="h-4 w-4">{action.icon}</span>
              ) : (
                <span>{action.title}</span>
              )}
            </button>
          </div>
        ))}
      </div>
      {props.placeholder && (!value || value.length === 0) && (
        <div className="relative">
          <div className="absolute top-0 px-3 py-2 text-sm text-gray-400">
            {props.placeholder}
          </div>
        </div>
      )}
      <div
        ref={element}
        className="prose focus:ring-primary-500 relative block min-h-28 w-full max-w-full overflow-y-auto rounded-b-md border-0 bg-transparent px-3 py-2 text-sm text-gray-900 placeholder-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 disabled:cursor-not-allowed disabled:opacity-75"
        contentEditable={true}
        onInput={update}
        onBlur={update}
        onPaste={onPaste}
        onKeyDown={onKeypress}
        onFocus={onFocus}
        onClick={onEditableClick}></div>
    </div>
  );
}
